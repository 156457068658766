import { useEffect, useState } from "react";
import { Col, CardBody, Row, Label, CardFooter, Input } from "reactstrap";
import { Button } from "reactstrap";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import SimpleInput from "../../../Components/Inputs/simpleInput";
import Select from "react-select";
import SweetAlert from "sweetalert2";
import { useNavigate, useLocation } from "react-router-dom";
import {
  createEstablishmentWithRoleAsync,
  updateEstablishmentAsync,
} from "../../../redux-toolkit/slices/establishmentSlice";
import MapComponent from "../../../CommonElements/Map";
import {
  CurrencyData,
  REGIONS,
  TYPE_ESTABLISHMENT,
} from "../../../Constant/index";
import Swipper from "../../../Components/Swiper/Swiper";
import { dynamicImage } from "../../../Service";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const EstablishmentsForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  let location = useLocation();
  const pathname = location.pathname.split("/");

  const initialPosition = [34.30714385628804, 9.492187500000002];
  const [position, setPosition] = useState(initialPosition);
  console.log("🚀 ~ EstablishmentsForm ~ position:", position);

  const [oldImages, setOldImages] = useState(location?.state?.images);

  const zoom = 5;

  useEffect(() => {
    if (location?.state?.position) {
      console.log(
        "🚀 ~ useEffect ~ location?.state?.position:",
        location?.state?.position
      );
      const data = JSON.parse(location?.state?.position);
      setPosition(data);
    }
  }, [location?.state?.position]);

  useEffect(() => {
    console.log("🚀 ~ useEffect data ~ position:", position);
  }, [position]);

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      zIndex: 1001,
    }),
  };

  const handlePositionChange = (newPosition) => {
    setPosition(newPosition);
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setValue("images", files);
  };

  const schema = yup.object().shape({
    name: yup.string().required("You must enter the name"),
    description: yup.string().required("You must enter the description"),
    rate: yup
      .number()
      .typeError("Rate must be a number")
      .positive("Rate must be a positive number"),
    phone: yup
      .string()
      .required("You must enter the phone number")
      .matches(/^[0-9]+$/, "Phone number must contain only digits")
      .min(8, "Phone number must be at least 10 digits")
      .max(13, "Phone number must be at most 15 digits"),
    currency: yup
      .object()
      .shape({
        label: yup.string().required("Currency label is required"),
        value: yup.string().required("Currency value is required"),
      })
      .required("You must select a currency"), // Currency validation
  });

  const {
    control,
    handleSubmit,
    reset,
    resetField,
    formState: { errors },
    setValue,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      images: null,
      oldImages: oldImages,
      name: location?.state?.name || "",
      description: location?.state?.description || "",
      rate: location?.state?.rate || "",
      type: {
        label: location?.state?.type || "",
        value: location?.state?.type || "",
      },
      position: location?.state?.position || initialPosition,
      phone: location?.state?.phone || "",
      currency: location?.state?.currency
        ? {
            label: location?.state?.currency,
            value: location?.state?.currency,
          }
        : { label: "", value: "" },
      region: {
        label: location?.state?.region || "",
        value: location?.state?.region || "",
      },
    },
  });

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("description", data.description);
    formData.append("region", data.region.value);
    formData.append("rate", data.rate);
    formData.append("phone", data.phone);
    formData.append("type", data.type.value);
    formData.append("currency", data.currency.value);
    formData.append("position", JSON.stringify(position));
    formData.append("onModel", "Establishment");
    formData.append("oldImages", oldImages);

    for (let i = 0; i < data?.images?.length; i++) {
      formData.append("images", data.images[i]);
    }

    if (!pathname.includes("edit")) {
      setPosition(location?.state?.position);
      const establishmentAdded = await dispatch(
        createEstablishmentWithRoleAsync(formData)
      );
      if (createEstablishmentWithRoleAsync.rejected.match(establishmentAdded)) {
        SweetAlert.fire("Error", establishmentAdded.payload, "error");
      } else {
        SweetAlert.fire(
          "Added!",
          establishmentAdded.payload.message,
          "success"
        );
        reset();
        navigate("/affectation-manager/establishments");
      }
    } else {
      const establishmentUpdated = await dispatch(
        updateEstablishmentAsync({
          establishmentId: location?.state?._id,
          establishmentData: formData,
        })
      );
      if (updateEstablishmentAsync.rejected.match(establishmentUpdated)) {
        SweetAlert.fire("Error", establishmentUpdated.payload, "error");
      } else {
        SweetAlert.fire(
          "Updated!",
          establishmentUpdated.payload.message,
          "success"
        );
        navigate("/affectation-manager/establishments");
      }
    }
  };

  return (
    <div className="page-body">
      <Breadcrumbs />
      <Col xl="12">
        <form className="card">
          <CardBody>
            <Row>
              <Col xl="3">
                <Row className="d-flex flex-column">
                  <Col xl="12" className="d-inline-block w-auto">
                    <Controller
                      name="oldImages"
                      control={control}
                      render={({ field }) => (
                        <>
                          {oldImages && oldImages.length > 0 ? (
                            <Swipper
                              oldImages={oldImages}
                              setOldImages={setOldImages}
                              dispatch={dispatch}
                            />
                          ) : (
                            <div className="d-flex align-item-center justify-content-center w-100 h-100">
                              <Image
                                src={dynamicImage("ecommerce/options.png")}
                                className=" m-auto col-sm-4  d-block img-fluid  "
                                style={{ minHeight: "180px" }}
                              />
                            </div>
                          )}
                        </>
                      )}
                    />
                  </Col>
                  <Col xl="12" md="12" className="p-t-15">
                    {/* <Label className="col-form-label pt-0">{t("Images")}</Label> */}
                    <Controller
                      name="images"
                      control={control}
                      render={({ field }) => (
                        <Input
                          type="file"
                          multiple
                          onChange={(e) => {
                            field.onChange(e);
                            handleFileChange(e);
                          }}
                        />
                      )}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xl="9">
                <Row>
                  <Col md="6">
                    <Label>Establishment name</Label>
                    <Controller
                      name="name"
                      control={control}
                      render={({ field }) => (
                        <SimpleInput
                          {...field}
                          errclassname="error-msg-input"
                          className="form-control"
                          placeholder="Name"
                          errors={errors.name?.message}
                        />
                      )}
                    />
                  </Col>
                  <Col md="6">
                    <Label>Description</Label>
                    <Controller
                      name="description"
                      control={control}
                      render={({ field }) => (
                        <SimpleInput
                          {...field}
                          errclassname="error-msg-input"
                          className="form-control"
                          placeholder="Description"
                          errors={errors.description?.message}
                        />
                      )}
                    />
                  </Col>
                </Row>
                <Row style={{ paddingTop: "10px" }}>
                  <Col md="6">
                    <Label>Region</Label>
                    <Controller
                      name="region"
                      control={control}
                      render={({ field }) => (
                        <>
                          <Select
                            options={REGIONS?.map((r) => ({
                              label: r.label,
                              value: r.value,
                            }))}
                            {...field}
                            onChange={(selected) => field.onChange(selected)}
                            styles={customStyles}
                          />
                          {errors.region && (
                            <span style={{ color: "red" }}>
                              {errors.region.message}
                            </span>
                          )}
                        </>
                      )}
                    />
                  </Col>
                  <Col md="6">
                    <Label>Rate</Label>
                    <Controller
                      name="rate"
                      control={control}
                      render={({ field }) => (
                        <SimpleInput
                          {...field}
                          errclassname="error-msg-input"
                          className="form-control"
                          placeholder="rate"
                          errors={errors.rate?.message}
                        />
                      )}
                    />
                  </Col>
                </Row>
                <Row style={{ paddingTop: "10px" }}>
                  <Col md="4">
                    <Label>Type</Label>
                    <Controller
                      name="type"
                      control={control}
                      render={({ field }) => (
                        <>
                          <Select
                            options={TYPE_ESTABLISHMENT?.map((t) => ({
                              label: t.label,
                              value: t.value,
                            }))}
                            {...field}
                            onChange={(selected) => field.onChange(selected)}
                            styles={customStyles}
                          />
                          {errors.type && (
                            <span style={{ color: "red" }}>
                              {errors.type.message}
                            </span>
                          )}
                        </>
                      )}
                    />
                  </Col>
                  <Col md="4">
                    <Label>Currency</Label>
                    <Controller
                      name="currency"
                      control={control}
                      render={({ field }) => (
                        <>
                          <Select
                            options={CurrencyData?.map((t) => ({
                              label: t.label,
                              value: t.value,
                            }))}
                            {...field}
                            onChange={(selected) => field.onChange(selected)}
                            styles={customStyles}
                          />
                          {errors.currency && (
                            <span style={{ color: "red" }}>
                              {errors.currency.message}
                            </span>
                          )}
                        </>
                      )}
                    />
                  </Col>
                  <Col md="4">
                    <Label>Phone</Label>
                    <Controller
                      name="phone"
                      control={control}
                      render={({ field }) => (
                        <SimpleInput
                          {...field}
                          errclassname="error-msg-input"
                          className="form-control"
                          placeholder="Phone"
                          errors={errors.phone?.message}
                        />
                      )}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row style={{ paddingTop: "10px" }}>
              <Label>Select the position</Label>
              <MapComponent
                initialPosition={position}
                zoom={zoom}
                style={{ width: "100%", height: "400px", marginTop: "5px" }}
                onPositionChange={handlePositionChange}
              />
            </Row>
          </CardBody>
          <CardFooter className="text-end">
            <Button onClick={handleSubmit(onSubmit)} color="secondary">
              Save
            </Button>
          </CardFooter>
        </form>
      </Col>
    </div>
  );
};
export default EstablishmentsForm;
