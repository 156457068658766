import { Container, Row } from "reactstrap";
import { Image } from "../../../AbstractElements";
import { useDispatch, useSelector } from "react-redux";
import SweetAlert from "sweetalert2";
import {
  deleteEstablishmentAsync,
  getAllEstablishmentsAsync,
} from "../../../redux-toolkit/slices/establishmentSlice";
import TableComp from "../../../Components/dataTable/TableComp";
import { useEffect, useState } from "react";
import { Edit, Trash } from "react-feather";
import { dynamicImage } from "../../../Service";
import { useNavigate } from "react-router-dom";

const EstablishmentPageContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const establishmentsData = useSelector(
    (state) => state.establishment.establishments
  );
  useEffect(() => {
    dispatch(getAllEstablishmentsAsync({ page: 1, limit: 10 }));
  }, [dispatch]);

  const handelNavigateEditService = (row) => {
    const rowName = row._id.replace(/ /g, "-");
    navigate(`/affectation-manager/establishments/edit/${rowName}`, {
      state: row,
    });
  };

  const handelDeleteEstablishment = async (row) => {
    const establishmentId = row?._id;
    if (!establishmentId) {
      return;
    }
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Are you sure you want to delete this establishment?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.value) {
        try {
          const deletedEstablishment = await dispatch(
            deleteEstablishmentAsync(establishmentId)
          );
          if (deleteEstablishmentAsync.fulfilled.match(deletedEstablishment)) {
            SweetAlert.fire(
              "Deleted",
              deletedEstablishment.payload.data.message,
              "success"
            );
            dispatch(getAllEstablishmentsAsync({ page: 1, limit: 10 }));
          } else {
            SweetAlert.fire(
              "Error!",
              `${deletedEstablishment.payload}`,
              "error"
            );
          }
        } catch (error) {
          SweetAlert.fire(
            "Error!",
            "Establishment could not be deleted.",
            "error"
          );
        }
      }
    });
  };

  const EstablishmentsTableColumns = [
    {
      name: "Image",
      selector: (row) => (
        <div>
          <Image
            src={
              row?.images[0]
                ? process.env.REACT_APP_MINIO_URL + row?.images[0]
                : dynamicImage("ecommerce/options.png")
            }
            style={{ width: "70px", padding: "10px" }}
          />
        </div>
      ),
      sortable: true,
      center: true,
      width: "180px",
    },
    {
      name: "Name",
      selector: (row) => row["name"],
      sortable: true,
      center: true,
      // width: '200px'
    },
    {
      name: "Description",
      selector: (row) => row?.description,
      sortable: true,
      center: true,
      width: "200px",
    },
    {
      name: "Region",
      selector: (row) => row?.region,
      sortable: true,
      center: true,
      width: "160px",
    },
    {
      name: "Rate",
      selector: (row) => row?.rate,
      sortable: true,
      center: true,
      width: "100px",
    },
    {
      name: "Phone",
      selector: (row) => row?.phone,
      sortable: true,
      center: true,
      width: "160px",
    },
    {
      name: "Type",
      selector: (row) => row?.type,
      sortable: true,
      center: true,
      width: "150px",
    },
    {
      name: "Action",
      selector: (row) => (
        <div>
          {/* <a
            className="btn-xs"
            // onClick={() => handelOpenToView(row)}
            style={{ cursor: "pointer" }}
          >
            <Trash
              width={20}
              color="red"
              onClick={() => handelDeleteEstablishment(row)}
            />
          </a> */}
          <button
            color="link"
            style={{ cursor: "not-allowed", border: "none", padding: 0 }}
            onClick={() => handelDeleteEstablishment(row)}
            disabled
          >
            <Trash width={20} color="red" />
          </button>
          <a
            className="btn-xs"
            title="Edit"
            onClick={() => handelNavigateEditService(row)}
            style={{ cursor: "pointer" }}
          >
            <Edit width={20} />
          </a>
        </div>
      ),
      sortable: false,
      center: true,
      width: "130px",
    },
  ];
  const customStyles = {
    headCells: {
      style: {
        color: "#202124",
        fontSize: "14px",
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: "rgb(230, 244, 244)",
        borderBottomColor: "#FFFFFF",
        outline: "1px solid #FFFFFF",
        cursor: "pointer",
      },
    },
    pagination: {
      style: {
        border: "none",
      },
    },
  };
  return (
    <Container fluid={true}>
      <Row>
        <TableComp
          data={establishmentsData}
          columns={EstablishmentsTableColumns}
          toggleDelete={false}
          expandableRows={false}
          highlightOnHover={true}
          fixedHeader={true}
          customStyles={customStyles}
        />
      </Row>
    </Container>
  );
};
export default EstablishmentPageContainer;
